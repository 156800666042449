import React from "react";
import Navbar from "./global-components/navbar";
import PageHeader from "./global-components/page-header";
// import ServiceDetails from './section-components/service-details';
import CallToActionV1 from "./section-components/call-to-action-v1";
import Footer from "./global-components/footer";
import ServiceDetailsTwo from "./section-components/service-details-two";

const Service_Details_Two = () => {
  return (
    <div>
      <Navbar />
      <PageHeader headertitle="Overseas Consultant" subheader="Overseas Consultant" />

      <ServiceDetailsTwo />
      <CallToActionV1 />
      <Footer />
    </div>
  );
};

export default Service_Details_Two;
