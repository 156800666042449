import React from "react";
import Navbar from "./global-components/navbar";
import PageHeader from "./global-components/page-header";
import AboutV5 from "./section-components/about-v5";
import ServiceV1 from "./section-components/service-v1";
import BlogSlider from "./blog-components/blog-slider-v1";
import CallToActionV1 from "./section-components/call-to-action-v1";
import Footer from "./global-components/footer";
import PrivacyContent from "./section-components/Privacy-content";
import NavbarV2 from "./global-components/navbar-v2";

const Service_V1 = () => {
  return (
    <div>
      <NavbarV2 />
      <PageHeader headertitle="Privacy Policy" subheader="Policy" />
      <PrivacyContent />
      <div className="" style={{paddingLeft:"20%",paddingRight:"20%"}}>
        <p>
          This Privacy Policy explains how AMR International collects, uses, and
          protects your personal information. By using our Website, you agree to
          the terms outlined in this policy.
        </p>
        <h2 className="" style={{padding:"10px"}}>1. Information We Collect</h2>
        <p>We may collect the following types of information:</p>
        <ul>
          <li>
            <strong>Personal Information:</strong> Name, email address, phone
            number, address, date of birth, and other details provided during
            inquiries or consultations.
          </li>
          <li>
            <strong>Service-Related Information:</strong> Information necessary
            to process visa applications or provide consultation services.
          </li>
          <li>
            <strong>Technical Information:</strong> IP address, browser type,
            operating system, and usage data to improve your experience on our
            Website.
          </li>
        </ul>
        <h2>2. How We Use Your Information</h2>
        <p>We use your information for the following purposes:</p>
        <ul>
          <li>To provide and improve our services.</li>
          <li>To process inquiries, consultations, and visa applications.</li>
          <li>
            To communicate with you regarding updates, offers, or promotions
            related to our services.
          </li>
          <li>
            To comply with legal obligations and ensure the security of our
            Website.
          </li>
        </ul>
        <h2>3. Sharing Your Information</h2>
        <p>
          We do not sell or rent your information. However, we may share your
          data in the following cases:
        </p>
        <ul>
          <li>
            With trusted third-party service providers who assist in delivering
            our services.
          </li>
          <li>When required by law, regulation, or court order.</li>
          <li>
            To protect our rights, users, or the public as permitted by law.
          </li>
        </ul>
        <h2>4. Data Security</h2>
        <p>
          We implement reasonable technical and organizational measures to
          protect your personal data from unauthorized access, disclosure, or
          misuse. However, no data transmission over the internet is entirely
          secure, and we cannot guarantee absolute security.
        </p>
        <h2>5. Cookies and Tracking Technologies</h2>
        <p>
          Our Website may use cookies to enhance your browsing experience. You
          can manage your cookie preferences through your browser settings.
        </p>
        <h2>6. Your Rights</h2>
        <p>You have the following rights regarding your data:</p>
        <ul>
          <li>To access and request a copy of your data.</li>
          <li>To correct or update your personal information.</li>
          <li>
            To request deletion of your data, subject to legal and contractual
            obligations.
          </li>
          <li>
            To opt out of marketing communications by contacting us directly.
          </li>
        </ul>
        <h2>7. Third-Party Links</h2>
        <p>
          Our Website may contain links to external sites. We are not
          responsible for the privacy practices or content of these sites.
        </p>
        <h2>8. Changes to This Privacy Policy</h2>
        <p>
          We may update this Privacy Policy from time to time. The revised
          policy will be posted on this page with the updated effective date.
        </p>
        <h2>9. Contact Us</h2>
        <p>
          If you have any questions or concerns about this Privacy Policy,
          please contact us at:
        </p>
        <p>
          <strong>AMR International</strong>
        </p>
        Email:{" "}
        <a href="mailto:hr.amrinternational@gmail.com">
          hr.amrinternational@gmail.com
        </a>
        <p>
          This Privacy Policy ensures transparency about the data we collect and
          how we use it. By using our Website, you agree to the terms of this
          Privacy Policy.
        </p><br />
      </div>
      {/* <BlogSlider sectionClass="pt-120" /> */}
      <CallToActionV1 />
      <Footer />
    </div>
  );
};

export default Service_V1;
