import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class Testimonial extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let imagealt = "image";

    return (
      <div
        className="ltn__testimonial-area section-bg-1--- bg-image-top pt-115 pb-70"
        data-bs-bg={publicUrl + "assets/img/bg/20.jpg"}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title-area ltn__section-title-2--- text-center">
                <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Our Testimonial</h6>
                <h1 className="section-title">Clients Feedback</h1>
              </div>
            </div>
          </div>
          <div className="row ltn__testimonial-slider-5-active slick-arrow-1">
            {/* card */}
            <div className="col-lg-4">
              <div className="ltn__testimonial-item ltn__testimonial-item-7">
                <div className="ltn__testimoni-info">
                  <p>
                    <i className="flaticon-left-quote-1" />
                    AMR International made my dream of studying in Canada a reality. Their team guided me through every
                    step of the visa process, answering all my questions and easing my worries. I couldn’t have done it
                    without their expertise and support!
                  </p>
                  <div className="ltn__testimoni-info-inner">
                    {/* <div className="ltn__testimoni-img">
                      <img src={publicUrl + "assets/img/testimonial/1.jpg"} alt="#" />
                    </div> */}
                    <div className="ltn__testimoni-name-designation">
                      <h5>Ravi M </h5>
                      <label>Thrissur</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* end card */}
            {/* card */}
            <div className="col-lg-4">
              <div className="ltn__testimonial-item ltn__testimonial-item-7">
                <div className="ltn__testimoni-info">
                  <p>
                    <i className="flaticon-left-quote-1" />
                    The team at AMR International is incredibly knowledgeable and supportive. They provided clear
                    instructions and managed my work visa application smoothly. Their service was fast and reliable,
                    making my move to Dubai hassle-free!
                  </p>
                  <div className="ltn__testimoni-info-inner">
                    {/* <div className="ltn__testimoni-img">
                      <img src={publicUrl + "assets/img/testimonial/2.jpg"} alt="#" />
                    </div> */}
                    <div className="ltn__testimoni-name-designation">
                      <h5>Anjali S</h5>
                      <label>Kochi</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* end card */}
            {/* card */}
            <div className="col-lg-4">
              <div className="ltn__testimonial-item ltn__testimonial-item-7">
                <div className="ltn__testimoni-info">
                  <p>
                    <i className="flaticon-left-quote-1" />I highly recommend AMR International for anyone looking to
                    settle abroad. They helped us with our family visa, and their attention to detail was impressive.
                    The process was clear and organized, and we felt taken care of from start to finish.
                  </p>
                  <div className="ltn__testimoni-info-inner">
                    {/* <div className="ltn__testimoni-img">
                      <img src={publicUrl + "assets/img/testimonial/3.jpg"} alt="#" />
                    </div> */}
                    <div className="ltn__testimoni-name-designation">
                      <h5>Faizal K</h5>
                      <label> Malappuram</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* end card */}

            {/*  */}
          </div>
        </div>
      </div>
    );
  }
}

export default Testimonial;
