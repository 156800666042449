import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import abouImg from "../../assets/images/about/amr-abt.png";
import abouImgII from "../../assets/images/about/AmrAbtsmall.png";

class AboutV1 extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="ltn__about-us-area pt-120 pb-90 " id="about">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 align-self-center">
              <div className="about-us-img-wrap about-img-left">
                <img src={abouImg} alt="Abt Img" />
                <div className="about-us-img-info about-us-img-info-2 about-us-img-info-3">
                  <div className="ltn__video-img ltn__animation-pulse1">
                    <img src={abouImgII} alt="AboutImg" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 align-self-center">
              <div className="about-us-info-wrap">
                <div className="section-title-area ltn__section-title-2--- mb-20 text-justify">
                  {/* <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">About Us</h6> */}
                  <h1 className="section-title">
                    About Us<span>.</span>
                  </h1>
                  <p>
                    Welcome to AMR International – Your trusted partner for overseas consultancy and visa services. With
                    over a decade of experience, AMR International has been a beacon of guidance and support for
                    individuals and families embarking on their journeys abroad. Our mission is to make international
                    relocation and immigration simple, secure, and successful for all our clients.
                  </p>
                  <p>
                    Founded with a commitment to excellence and a passion for helping dreams take flight, we have helped
                    Hundreds of clients from diverse backgrounds achieve their goals of studying, working, and settling
                    overseas. Our dedicated team of experts brings in-depth knowledge of global immigration policies and
                    procedures, ensuring that every client receives personalised and up-to-date guidance tailored to
                    their unique needs. At AMR International, we specialise in a wide range of visa services, including
                    student visas, work permits, family and dependent visas, and permanent residency consultations. Our
                    reputation for integrity and reliability has made us a preferred choice for clients who value a
                    smooth, transparent, and stress-free immigration process.
                  </p>
                  <p>
                    With AMR International by your side, your global aspirations are within reach. Let us navigate the
                    complexities of international travel and residency, while you focus on building a brighter future
                  </p>
                </div>

                {/* <div className="btn-wrapper animated go-top">
                  <Link to="/service" className="theme-btn-1 btn btn-effect-1">
                    OUR SERVICES
                  </Link>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AboutV1;
