import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import visaIcon from "../../assets/icons/visa.png";
import overSeas from "../../assets/icons/overseasCons.png";

class FeaturesV1 extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    let customClass = this.props.customClass ? this.props.customClass : "";

    return (
      <div className={customClass} id="services">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title-area ltn__section-title-2--- text-center">
                {/* <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Our Services</h6> */}
                <h1 className="section-title">Our Services </h1>
              </div>
            </div>
          </div>
          <div className="row ltn__custom-gutter--- justify-content-center go-top">
            <div className="col-lg-4 col-sm-6 col-12">
              <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
                <div className="ltn__feature-icon">
                  <img src={visaIcon} alt="#" />
                </div>
                <div className="ltn__feature-info">
                  <h3>
                    <Link to="/service-details">Visa Services </Link>
                  </h3>
                  <p>
                    AMR International offers comprehensive visa services to make your overseas journey as smooth and
                    hassle-free as possible...
                  </p>
                  <Link className="ltn__service-btn" to="/service-details">
                    View More <i className="flaticon-right-arrow" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-12">
              <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
                <div className="ltn__feature-icon">
                  <img src={overSeas} alt="#" />
                </div>
                <div className="ltn__feature-info">
                  <h3>
                    <Link to="/service-details">Overseas Consultant </Link>
                  </h3>
                  <p>
                    As a trusted overseas consultancy, AMR International is dedicated to guiding you through every step
                    of your international journey...
                  </p>
                  <Link className="ltn__service-btn" to="/service-details-two">
                    View More <i className="flaticon-right-arrow" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FeaturesV1;
