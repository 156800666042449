import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class Social extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="ltn__social-media">
        <ul>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.facebook.com/profile.php?id=61567682750434&mibextid=ZbWKwL"
              title="Facebook"
            >
              <i className="fab fa-facebook-f" />
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.instagram.com/amr_international__?igsh=MXdjMzZuYWFmc3QzMA=="
              title="Instagram"
            >
              <i className="fab fa-instagram" />
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://wa.me/+919567695512"
              title="whatsapp"
            >
              <i className="fab fa-whatsapp" />
            </a>
          </li>
        </ul>
      </div>
    );
  }
}

export default Social;
