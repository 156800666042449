import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class ContactInfo extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="ltn__contact-address-area mb-90">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow h-100">
                <div className="ltn__contact-address-icon">
                  <img src={publicUrl + "assets/img/icons/10.png"} alt="Icon Image" />
                </div>
                <h3>Email Address</h3>
                <p>hr.amrinternationalathani@gmail.com</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow h-100">
                <div className="ltn__contact-address-icon">
                  <img src={publicUrl + "assets/img/icons/11.png"} alt="Icon Image" />
                </div>
                <h3>Phone Number</h3>
                <p>
                  +91 95676 95512
                  <br /> +91 95399 22074
                  <br /> +91 80892 46168
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow h-100">
                <div className="ltn__contact-address-icon">
                  <img src={publicUrl + "assets/img/icons/12.png"} alt="Icon Image" />
                </div>
                <h3>Office Address</h3>
                <p>
                  VIP Panorama Arcade, near to South, Indian Bank, Nedumbassery,
                  <br /> Athani, Kochi, Kerala 683572
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactInfo;
