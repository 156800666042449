import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import bannerOne from "../../assets/images/banner/banner1.webp";
import bannerTwo from "../../assets/images/banner/banner2.webp";
import bannerThree from "../../assets/images/banner/banner3.webp";

class BannerV3 extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let imagealt = "image";

    return (
      <div className="ltn__slider-area ltn__slider-3  section-bg-2">
        <div className="ltn__slide-one-active slick-slide-arrow-1 slick-slide-dots-1">
          {/* ltn__slide-item */}
          <div
            className="ltn__slide-item ltn__slide-item-2 ltn__slide-item-3-normal--- ltn__slide-item-3 bg-image bg-overlay-theme-black-60"
            data-bs-bg={bannerOne}
          >
            <div className="ltn__slide-item-inner text-center">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 align-self-center">
                    <div className="slide-item-info">
                      <div className="slide-item-info-inner ltn__slide-animation">
                        <h1 className="slide-title animated ">
                          Your Gateway to <br />
                          Global Opportunities
                        </h1>
                        <div className="slide-brief animated">
                          <p>Simplifying your journey abroad with expert visa and immigration solutions.</p>
                        </div>
                        <div className="btn-wrapper animated go-top">
                          <Link to="/contact" className="theme-btn-1 btn btn-effect-1">
                            Make An Enquiry
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* ltn__slide-item */}
          <div
            className="ltn__slide-item ltn__slide-item-2  ltn__slide-item-3-normal--- ltn__slide-item-3 bg-image bg-overlay-theme-black-60"
            data-bs-bg={bannerTwo}
          >
            <div className="ltn__slide-item-inner  text-right text-end">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 align-self-center">
                    <div className="slide-item-info">
                      <div className="slide-item-info-inner ltn__slide-animation">
                        {/* <h6 className="slide-sub-title white-color--- animated">
                          <span>
                            <i className="fas fa-home" />
                          </span>{" "}
                          
                        </h6> */}
                        <h1 className="slide-title animated ">
                          Your Trusted Partner for <br />
                          Visa Services and Study Abroad Dreams
                        </h1>
                        <div className="slide-brief animated">
                          <p>
                            AMR International, With over a decade of expertise, we are dedicated to helping you achieve
                            your international aspirations with ease and confidence. Your journey to a brighter
                            future starts here!
                          </p>
                        </div>
                        <div className="btn-wrapper animated go-top">
                          <Link to="/contact" className="theme-btn-1 btn btn-effect-1">
                            Make An Enquiry
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BannerV3;
