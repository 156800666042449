import React, { useState, useEffect } from "react";
import whatsAppIcon from "../../src/assets/icons/whatsapp.png";
// import "./WhatsAppButton.css";

const WhatsAppButton = () => {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    showButton && (
      <div className="whatsapp-btn">
        <a href="https://wa.me/+919567695512" rel="noopener noreferrer" aria-label="Chat with us on WhatsApp">
          <img src={whatsAppIcon} alt="WhatsApp Icon" />
        </a>
      </div>
    )
  );
};

export default WhatsAppButton;
