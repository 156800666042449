import React from "react";
import Social from "../section-components/social";

function AnnouncementBar() {
  return (
    <div style={styles.container}>
      <div className="ltn__top-bar-menu" style={styles.menu}>
        <ul style={styles.ul}>
          <li style={styles.li}>
            <a href="mailto:hr.amrinternationalathani@gmail.com" style={styles.link}>
              <i className="icon-mail" /> hr.amrinternationalathani@gmail.com
            </a>
          </li>
          <li style={styles.li}>
            <a
              href="https://maps.app.goo.gl/jhPbQVVzxQPjkaba8"
              target="_blank"
              rel="noopener noreferrer"
              style={styles.link}
            >
              <i className="icon-placeholder" />
              VIP Panorama Arcade, near to South, Indian Bank, Nedumbassery,
              Athani, Kochi, Kerala 683572
            </a>
          </li>
        </ul>
      </div>
      <Social />
    </div>
  );
}

const styles = {
  container: {
    display: "flex",
    flexDirection: "column", // Arrange items in a column
    justifyContent: "center", // Vertically center items
    alignItems: "center", // Horizontally center items
    textAlign: "center",
    padding: "10px",
  },
  menu: {
    display: "flex",
    flexDirection: "column", // Stack the items vertically
    alignItems: "center", // Center align the menu items
    justifyContent: "center",
  },
  ul: {
    listStyle: "none",
    padding: "0",
    margin: "0",
  },
  li: {
    margin: "10px 0", // Spacing between items
  },
  link: {
    textDecoration: "none",
    color: "inherit",
    fontSize: "16px", // You can adjust the font size here
  },
};

export default AnnouncementBar;
