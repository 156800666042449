import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import overSeasConsultantImg from "../../assets/images/services/overseasCons-web.webp";

class ServiceDetailsTwo extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="ltn__page-details-area ltn__service-details-area mb-105">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="ltn__page-details-inner ltn__service-details-inner">
                <div className="ltn__blog-img">
                  <img src={overSeasConsultantImg} alt="Image" />
                </div>
                <p>
                  As a trusted overseas consultancy, AMR International is dedicated to guiding you through every step of
                  your international journey. From career counseling and educational opportunities to visa assistance
                  and relocation advice, our experienced consultants are here to provide reliable, customized solutions.
                  We understand the unique challenges of moving abroad, and our personalized support aims to turn your
                  dreams into reality.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ServiceDetailsTwo;
