import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import bgImg from "../../assets/images/homePage/amr-bg.png";

class VideoV1 extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let imagealt = "image";

    return (
      <div className="ltn__video-popup-area ltn__video-popup-margin---">
        <div
          className="ltn__video-bg-img ltn__video-popup-height-600--- bg-overlay-black-30 bg-image bg-fixed ltn__animation-pulse1"
          data-bs-bg={bgImg}
        >
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <h1 className="section-title text-white ">
                  Our Vision<span>.</span>
                </h1>
                <p className="text-white">
                  To be a leading consultancy in overseas immigration, empowering individuals and families to realize
                  their international aspirations with confidence and ease. We strive to set the standard in the
                  industry by providing unparalleled expertise, personalized support, and a seamless experience that
                  inspires trust and success in every journey abroad.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default VideoV1;
