import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class CounterV1 extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="ltn__counterup-area section-bg-1 pt-120 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h1 className="section-title">
                Our Mission
                <span>.</span>
              </h1>
              <p>
                At AMR International, our mission is to provide trusted, transparent, and personalized visa and
                consultancy services that simplify the immigration journey and empower our clients to achieve their
                international goals. We are dedicated to guiding individuals and families with integrity and expertise,
                ensuring a seamless, stress-free experience as they embark on new opportunities abroad. Our commitment
                is to make international relocation accessible, informed, and successful for everyone we serve.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CounterV1;
