import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import visaSerImg from "../../assets/images/services/visa-servics-web.webp";

class ServiceDetails extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="ltn__page-details-area ltn__service-details-area mb-105">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="ltn__page-details-inner ltn__service-details-inner">
                <div className="ltn__blog-img">
                  <img src={visaSerImg} alt="Image" />
                </div>
                <p>
                  AMR International offers comprehensive visa services to make your overseas journey as smooth and
                  hassle-free as possible. With a decade of experience in the industry, our team provides personalized
                  assistance to help you navigate complex visa requirements, documentation, and application processes.
                  Whether you are applying for a student, work, tourist, or family visa, our expert guidance ensures
                  accuracy and efficiency, increasing your chances of success.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ServiceDetails;
