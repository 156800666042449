import React, { useState } from "react";

const ContactForm = () => {
  const [formMessage, setFormMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.target;

    const formData = new FormData(form);

    try {
      const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        setIsSuccess(true);
        setIsError(false);
        setFormMessage("Your message has been sent successfully!");
        form.reset(); // Clear the form
      } else {
        setIsSuccess(false);
        setIsError(true);
        setFormMessage("An error occurred. Please try again later.");
      }
    } catch (error) {
      setIsSuccess(false);
      setIsError(true);
      setFormMessage("Oops! An error occurred. Please try again.");
    }
  };

  return (
    <div className="ltn__contact-message-area mb-120 mb--100">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="ltn__form-box contact-form-box box-shadow white-bg">
              <h4 className="title-2">Contact Us</h4>
              <form id="contact-form" action="https://api.web3forms.com/submit" method="POST" onSubmit={handleSubmit}>
                {/* Include Web3Forms Access Key */}
                <input type="hidden" name="access_key" value="b0aad8ac-88a2-4750-9ee7-08c00c5c4e73" />

                <div className="row">
                  <div className="col-12">
                    <div className="input-item input-item-name ltn__custom-icon">
                      <input type="text" name="name" placeholder="Enter your name" required />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-item input-item-email ltn__custom-icon">
                      <input type="email" name="email" placeholder="Enter email address" required />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-item input-item-phone ltn__custom-icon">
                      <input type="text" name="phone" placeholder="Enter phone number" />
                    </div>
                  </div>
                </div>
                <div className="input-item input-item-textarea ltn__custom-icon">
                  <textarea name="message" placeholder="Enter message" required></textarea>
                </div>
                <input type="checkbox" name="botcheck" className="hidden" style={{ display: "none" }} />
                <div className="btn-wrapper mt-0">
                  <button className="btn theme-btn-1 btn-effect-1 text-uppercase" type="submit">
                    Get a Free Consultancy Call
                  </button>
                </div>
              </form>

              {/* Display form messages */}
              {formMessage && (
                <p className={`form-messege mb-0 mt-20 ${isSuccess ? "success" : "error"}`}>{formMessage}</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
